@import url('https://fonts.googleapis.com/css2?family=Ysabeau:wght@100&display=swap');

.inquire-main {
	box-sizing: border-box;
	margin: 0;
	padding: 0;

}

.inquire-h1 {
	text-align: center;
	font-family: 'Ysabeau', sans-serif;
}




.form-div {
	width: auto;
	display: flex;
	justify-content: center;
}

form {
	display: flex;
	flex-direction: column;
	font-size: 12px;
}



input {
	color: #a4a4a4;
	width: auto;
	height: 35px;
	padding: 7px;
	outline: none;
	border: 1px solid rgb(220, 228, 220);


}


.name-input {
	width: 50vmin;
	background: #F1EEE9;
	border: none;
	outline: none;
	border-bottom: 1px solid #ccc;
	padding: 1.2rem 0;
	letter-spacing: 0.049rem;
	color: #2e2e2e;


}

.user-input {
	width: 50vmin;
	background: #F1EEE9;
	border: none;
	outline: none;
	border-bottom: 1px solid #ccc;
	padding: 1.2rem 0;
	letter-spacing: 0.049rem;
	color: #2e2e2e;
}



textarea {

	height: 35vmin;
	width: 50vmin;
	padding: 1rem;
	border-radius: 0;
	appearance: none;
	background: #F1EEE9;
	line-height: 2rem;
	letter-spacing: 0.04em;
	resize: none;
	outline: none !important;
	border: 1px solid rgb(220, 228, 220);
	letter-spacing: 0.049rem;
	color: #2e2e2e;

}

label {
	margin-top: 1rem;


}

.message-label {
	margin-bottom: 5px;
}


input[type='submit'] {
	color: black;
	margin-top: 3rem;
	padding: 10px;
	padding-right: 2rem;
	padding-left: 2rem;
	background-color: #F1EEE9;
	border: 1px solid gray;
	border-radius: none;
}

.btn-submit:hover,
.btn-submit {
	background-color: black;
	color: #F1EEE9;
	width: 50vmin;
}

.inquire-div {
	display: flex;
	justify-content: center;
}

.inquire-ul {
line-height: 1.3rem;
}

.services-h3 {
	margin-top: 2rem;
	display: flex;
	justify-content: center;
	font-family: 'Ysabeau', sans-serif;
}

.message-sent {
	text-align: center;
	color: #7E481C;
	font-weight: bold;
	font-size: 17px;
	font-family: 'Ysabeau', sans-serif;
	
}