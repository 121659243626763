.ar-main {
	text-align: center;
}

.img1-ar {
	height: 55%;
	width: 55%;
}

.img2-ar {
	height:60%;
	width: 60%;
}

.img1-ar-div {
	margin-top: 5rem;
	margin-right: 10rem;
	display: flex;
	justify-content: right;
}

.img2-ar-div {
	margin-top: 15rem;

	display: flex;
	justify-content: center;
}

.img3-ar {
	margin-top: 5rem;
	margin-right: 5rem;
	height: 80%;
	width: 60%;
}

.img4-ar {
	margin-top: 10rem;
	height: 60%;
	width: 60%;;
}



.img5-ar {
	height: 100%;
	width: 50%;
	margin-top: 6rem;
	margin-left: 5rem;
}

.img6-ar {
	margin-top: 8rem;
	height: 80%;
	width: 70%;
}


.img8-ar {
	margin-top: 10rem;
	
}
@media (max-width: 768px) {

	.ar-main {
		box-sizing: border-box;
		margin: 0;
		padding: 0;
	}

	.img1-ar {
	height: 100%;
	width: 100%;
}

.img2-ar {
	height: 80%;
	width: 80%;
}
	.img3-ar {
		margin: 0;
	}

.img1-ar-div {
	margin-top: 5rem;
	display: flex;
	justify-content: center;
}

.img2-ar-div {
	margin-top: 10rem;
	display: flex;
	justify-content: center;
}


.img3-ar {
	height: 70%;
	width: 60%;
}

.img4-ar {
	margin-top: 0;
	height: 100%;
	width: 100%;
}

.img5-ar {
	margin-top: 1rem;
	margin-left: 2rem;
	height: 100%;
	width: 90%;
}

.img6-ar {
	margin: 0;
	height: 100%;
	width: 100%;
}
.img8-ar {
	height: 50%;
	width: 50%;
}
}