.hf {
	text-align: center;
	
}



.img1-hf, .img2-hf, .img3-hf, .img4-hf, .img5-hf  {
	height: 80%;
	width: 80%;
	margin-top: 8rem;
}