* {
  box-sizing: border-box;
}



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:#F1EEE9;


}

/* different shades of off white and neutral
off white rgb(246, 247, 241);
light beige #F5F1E7;
pinkish beige #F1EEE9; current color
grayish offwhite #f0f0ea;
soultwin color  #f7f7f2;
offwhitish beige #EEEBD9

 */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
