.type {
	text-align: center;
}

.type1 {
	height: 40%;
	width: 40%;
}

.div-type {
	margin-top: 7rem;
}