header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 60px;
	
}

nav {
	margin-top: 10rem;
	margin-left: 2rem;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	height: 100%;
	
}

ul {
	
	list-style-type: none;
	margin-left: 1.5rem;
	padding: 0;
	font-size: 11px;
	

}

ul li a {
line-height: 1.3rem;
color: black;
text-decoration: none;

}


a:hover {
	text-decoration: underline ;
	color: brown;
	
	

}

.logo-div {
	margin-top: 2rem;
	margin-right: 2rem;
	display: flex;
	justify-content: flex-end;
	align-items: center;

}

.logo {
	color: #5b3e31;
	text-decoration: none;
	font-size: 24px;
	
}

.hidden {
	display: none;
}


/* mobile menu test code */
/* @media only screen and (max-width:768px) {
	.nav-links {
		display: none;
	}

	.nav-links.active {
		display: flex;
		flex-direction: column;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		background-color: white;
		align-items: center;
		justify-content: center;
		z-index: 1001;

	}

	.bars-button {
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		width: 50px;
		height: 40px;
		z-index: 1002;
		position: relative;

	}

	.bars-button span {
		background-color: black;
		width: 100%;
		height: 2px;
		transition: all .3s ease;
	}

	.bars-button.active span:nth-child(1) {
		transform: rotate(45deg) translate(0.75em, 1.25em);
		transition: ease-out 0.2s;
	}

	.bars-button.active span:nth-child(2) {
		transform: scale(0.1);
		transition: ease-out 0.1s;
	}

	.bars-button.active span:nth-child(3) {
		transform: rotate(135deg) translate(-0.5em, 1em);
		transition: ease-out 0.2s;
	}
} */
/* 
.logo {
text-align: right;
	justify-content: flex-end;
	margin-right: 4rem;
	color: black;
	text-decoration: none;
}


nav {
	display: flex;
	justify-content: left;
	align-items: left;
	height: 100%;
}

ul {
	list-style-type: none;
	line-height: 1.2rem;
	font-size: 11px;
}


ul li a {
	color: #000;
} */