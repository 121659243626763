.r1s{
	text-align: center;
}


.div-r1s {
	margin-top: 7rem;
}

.img1-r1s {
	height: 40%;
	width: 40%;
}