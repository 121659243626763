.ip{
	text-align: center;
}

.iphone1ip-img {
	height: 20%;
	width: 20%;
}


.img1-ip-div {
	margin-top: 8rem;
}

.img1-ip {
	height: 30%;
	width: 30%;
	margin-left: 5rem;
}

.img2-ip {
	height: 20%;
	width: 20%;
	margin-right: 10rem;
	margin-bottom: 7rem;
}

.img3-ip {
	margin-top: 10rem;
	height: 50%;
	width: 50%;
}

.image-container-ip {
	align-items: center;
	float: left;
	display: flex;
	margin-top: 15rem;
}

.image-ip{
	float: left;
	
}

.img4-paragraph-ip {
	padding: 10rem;
	display: flex;

}

.img4-ip {
	height: 30%;
	width: 30%;
	margin-right: 7rem;
}

.img5-ip {
	margin-top: 9rem;
}

.img6-paragraph-ip {
	padding: 10rem;
	display: flex;

}

.img6-ip {
	height: 30%;
	width: 30%;
	margin-left: 7rem;

}



.img7-ip {
	height: 60%;
	width: 60%;
	margin-top: 7rem;
}

.img8-ip {
	height: 30%;
	width: 30%;
	margin-top: 4rem;
}

@media (max-width: 768px) {

	.ip{
		box-sizing: border-box;
		margin: 0;
		padding: 0;
	}
	.img1-ip {
	height: 50%;
	width: 50%;
	margin-left: 5rem;
}

.img2-ip {
	height: 40%;
	width: 40%;
	margin-right: 10rem;
	margin-bottom: 7rem;
}

.img3-ip {
	margin-top: 10rem;
	height: 80%;
	width: 80%;
}

.img4-ip {
	margin-left: 7rem;
	height: 60%;
	width: 60%;
}

.img5-ip {
	margin: 0;
	height: 80%;
	width: 80%;
}

.img6-ip {
	height: 70%;
	width: 70%;
}
.img7-ip {
	margin: 0;
	height: 60%;
	width: 80%;
}
}