footer {
	color: black;
	padding: 1rem;
	margin-top: 1rem;
	position: relative;
	bottom: 0;
	width: 100%;
	height: 7rem;
	padding-top: 15px;


	
}

.footer-ul {
	line-height: 1.5rem;
}

.copyright-div {
	margin-bottom: 2rem;
	display: flex;
	justify-content: center;
}
.copyright {
	font-size: 12px;
	margin-bottom: 5vmin;
}