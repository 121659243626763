.studio {
	text-align: center;
	
}

.studio-h1 {
	font-family: 'Ysabeau', sans-serif;
}


.gallery {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin-top: 5rem;
}


.image {
	width: auto;
	height: 38vh;
	object-fit: cover;
	margin: 29px;
	margin-top: 4rem;
}




.filter {
filter: brightness(93%);
	
}

.filter:hover {
    filter: grayscale(0);
    transform: scale(1.03);
    transition: filter 0.3s ease-in-out, transform 0.3s ease-in-out;
    filter: brightness(110%);
}

@media (max-width: 575px) {
  .gallery {
        margin-top: 3rem; /* Adjust the margin for smaller screens */
    }
   .image {
	height: auto;
        width: calc(43% - 14px); /* Adjust the width for smaller screens */
        margin: 13px; /* Adjust the margin for smaller screens */
    } 


  .filter:hover {
    filter: brightness(110%);
    transform: none; /* Reset transform */
    transition: none; /* Reset transition */
  }



.id-site {
	color: black;
}

}