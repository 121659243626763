.front {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 5rem;
	
}


@media only screen and (max-width: 730px) {
	.small-screen {
		height: 100%;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 5rem;
		margin-right: 4rem;
		margin-bottom: 6rem;

	}
}