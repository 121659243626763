.hs {
	text-align: center;
	
}



.img1-hs, .img2-hs, .img3-hs, .img4-hs, .img5-hs  {
	height: 80%;
	width: 80%;
	margin-top: 8rem;
}