@import url('https://fonts.googleapis.com/css2?family=Ysabeau:wght@100&display=swap');


.concierge-h1, .concierge-h2 {
	text-align: center;
	font-family: 'Ysabeau', sans-serif;
	
}

.concierge-h1 {
	margin-top: 10rem;
	font-size: 40px;
}

.concierge-h2 {
	margin-top: 2rem;
	font-size: 17px;
}

.privacy-p {

	display: flex;
		flex-direction: column;
		line-height: 1.5rem;
		font-size: 15px;
		margin: 0 30vmin 0 30vmin;
		padding: 5rem 15rem 30rem 15rem;
}

@media screen and (max-width: 768px) {

.privacy-policy {
		display: flex;
		flex-direction: column;
		line-height: 1.5rem;
		font-size: 15px;
		margin: 1rem 1rem 1rem 1rem;
		padding: 1rem 1rem 1rem 1rem;
	
	}
}